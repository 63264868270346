









































































































































import { Component, Mixins } from "vue-property-decorator";
import { base_class_pantalla } from "@/shared/classes/base-class-pantallas";
import { preguntas } from "@/shared/dtos/preguntas";
import { RouterNames } from "@/router/routernames";
import preguntasModule from "@/store/modules/preguntas-module";
import { PreguntaRespuestaDto } from "@/shared/dtos/PreguntaRespuestaDto";
import { respuestas } from "@/shared/dtos/respuestas";
import dashboardModule from "@/store/modules/dashboard-module";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    ConfigEncuestasFormularioRespuestas: () =>
      import("./config_encuestas_formulario_respuestas.vue"),
  },
})
export default class config_encuestas_formulario extends Mixins(
  base_class_pantalla
) {
  created() {
    this.nombre_pantalla = "Pregunta";
    this.toolbar_texto_editar = "Editar pregunta";
    this.toolbar_texto_crear = "Crear nueva pregunta";
    this.es_edicion = Number.parseInt(this.$route.params.id) > -1;
    if (this.es_edicion) {
      preguntasModule.getpreguntasAl(Number.parseInt(this.$route.params.id));
    } else {
      preguntasModule.onGetpreguntasAl(new PreguntaRespuestaDto());
    }
    dashboardModule.getdashboards();
  }
  public get datapregunta() {
    return preguntasModule.pregunta_entera;
  }
  public get dashboards() {
    return dashboardModule.dashboards;
  }
  public submit() {
    if (!this.es_edicion) {
      var pregunta = new PreguntaRespuestaDto();

      pregunta.preguntas = new preguntas(
        preguntasModule.pregunta_entera.preguntas
      );

      pregunta.preguntas.id = 0;
      pregunta.respuestas = preguntasModule.pregunta_entera.respuestas
        ? preguntasModule.pregunta_entera.respuestas.map((x) => {
            var k = new respuestas(x);
            k.id = 0;
            return k;
          })
        : [];

      preguntasModule.onGetpreguntasAl(pregunta);
    }
    if (this.es_edicion) {
      preguntasModule
        .modficarpreguntasAll(preguntasModule.pregunta_entera)
        .then(() => this.toList());
    } else {
      preguntasModule
        .guardarpreguntasAll(preguntasModule.pregunta_entera)
        .then(() => this.toList());
    }
  }
  public eliminar() {
    preguntasModule
      .eliminarpreguntas(preguntasModule.pregunta_entera.preguntas)
      .then(() => this.toList());
  }
  public toList() {
    this.$router.push({ name: RouterNames.preguntaslista });
  }
}
